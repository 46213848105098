















import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

import EstimationForm from "@/components/EstimationForm.vue";

@Component({
  components: { EstimationForm }
})
export default class EstimationPage extends Vue {}
